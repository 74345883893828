import React from 'react'
import styled from 'styled-components'

const HandbookStyles = styled.section`

`

export default function Handbook() {
  return (
    <HandbookStyles>
        <h1>Handbook</h1>
    </HandbookStyles>
  )
}
